import { createAction, props } from '@ngrx/store';
import {
  OptionOrderResponse,
  OptionRequest,
  OptionValitaionResponse,
} from '@paldesk/shared-lib/data-access/sso-generated';
import {
  CalculateCommand,
  CalculationInfo,
  Category,
  Construction,
  ConstructionConfig,
  ConstructionInfo,
  ConstructionInfoPaged,
  ConstructionWithAvailableConfig,
  Equipment,
  FileDownloadUri,
  TruckManufacturer,
} from '../../shared/generated';

export namespace CranesActions {
  /**
   * New calculation pop-up
   */
  export const LoadEquipments = createAction(
    '[Pacweb] LOAD_EQUIPMENTS',
    props<{ payload: string }>(),
  );
  export const LoadEquipmentsSuccess = createAction(
    '[Pacweb] LOAD_EQUIPMENTS_SUCCESS',
    props<{ payload: Equipment[] }>(),
  );
  export const LoadEquipmentsError = createAction(
    '[Pacweb] LOAD_EQUIPMENTS_ERROR',
  );

  export const SelectEquipment = createAction(
    '[Pacweb] SELECT_EQUIPMENT',
    props<{ payload: string }>(),
  );
  export const SelectCraneDataStatus = createAction(
    '[Pacweb] SELECT_CRANEDATA_STATUS',
    props<{ payload: string }>(),
  );
  export const ResetNewCalculationScreen = createAction(
    '[Pacweb] RESET_NEW_CALCULATION_SCREEN',
  );
  export const SetHelpUrl = createAction(
    '[Pacweb] SET_HELP_URL',
    props<{ payload: string }>(),
  );
  export const SetUserName = createAction(
    '[Pacweb] SET_USER_NAME',
    props<{ payload: string }>(),
  );

  /**
   * Existing calculation pop-up
   */
  export const LoadCalculationInfos = createAction(
    '[Pacweb] LOAD_CALCULATION_INFOS',
    props<{
      payload: { searchTerm?: string; pageSize: number; pageIndex: number };
    }>(),
  );
  export const LoadCalculationInfosSuccess = createAction(
    '[Pacweb] LOAD_CALCULATION_INFOS_SUCCESS',
    props<{ payload: ConstructionInfoPaged }>(),
  );
  export const LoadCalculationInfosError = createAction(
    '[Pacweb] LOAD_CALCULATION_INFOS_ERROR',
  );

  export const SelectCalculationId = createAction(
    '[Pacweb] SELECT_CALCULATION_ID',
    props<{ payload: string }>(),
  );
  export const ResetExistingCalculationScreen = createAction(
    '[Pacweb] RESET_EXISTING_CALCULATION_SCREEN',
  );

  /**
   * Import file pop-up
   */
  export const UploadCalculationFile = createAction(
    '[Pacweb] UPLOAD_CALCULATION_FILE',
    props<{ payload: File }>(),
  );

  export const UploadCalculationFileSuccess = createAction(
    '[Pacweb] UPLOAD_CALCULATION_FILE_SUCCESS',
    props<{ payload: ConstructionInfo }>(),
  );

  export const UploadCalculationFileError = createAction(
    '[Pacweb] UPLOAD_CALCULATION_FILE_ERROR',
  );

  /**
   * Calculation screen
   */
  export const LoadNewCalculation = createAction(
    '[Pacweb] LOAD_NEW_CALCULATION',
  );
  export const LoadCalculationByDebitorId = createAction(
    '[Pacweb] LOAD_EXISTING_CALCULATION_BY_DEBITOR_ID',
    props<{ calculationId: string; debitorId: string }>(),
  );
  export const LoadCalculation = createAction('[Pacweb] LOAD_CALCULATION');
  export const LoadNewCalculationSuccess = createAction(
    '[Pacweb] LOAD_NEW_CALCULATION_SUCCESS',
    props<{ payload: ConstructionWithAvailableConfig }>(),
  );
  export const LoadExistingCalculationSuccess = createAction(
    '[Pacweb] LOAD_EXISTING_CALCULATION_SUCCESS',
    props<{ payload: ConstructionWithAvailableConfig }>(),
  );
  export const LoadCalculationError = createAction(
    '[Pacweb] LOAD_CALCULATION_ERROR',
  );
  export const ResetCalculation = createAction('[Pacweb]RESET_CALCULATION');

  /**
   * Truck
   */
  export const LoadTruckManufacturers = createAction(
    '[Pacweb] LOAD_TRUCK_MANUFACTURERS',
  );
  export const LoadTruckManufacturersSuccess = createAction(
    '[Pacweb] LOAD_TRUCK_MANUFACTURERS_SUCCESS',
    props<{ payload: TruckManufacturer[] }>(),
  );
  export const LoadTruckManufacturersError = createAction(
    '[Pacweb] LOAD_TRUCK_MANUFACTURERS_ERROR',
  );

  /**
   * Crane
   */
  export const LoadCategories = createAction('[Pacweb] LOAD_CATEGORIES');
  export const LoadCategoriesSuccess = createAction(
    '[Pacweb] LOAD_CATEGORIES_SUCCESS',
    props<{ payload: Category[] }>(),
  );
  export const LoadCategoriesError = createAction(
    '[Pacweb] LOAD_CATEGORIES_ERROR',
  );

  export const LoadEpsilonCategories = createAction(
    '[Pacweb] LOAD_EPSILON_CATEGORIES',
  );
  export const LoadEpsilonCategoriesSuccess = createAction(
    '[Pacweb] LOAD_EPSILON_CATEGORIES_SUCCESS',
    props<{ payload: Category[] }>(),
  );
  export const LoadEpsilonCategoriesError = createAction(
    '[Pacweb] LOAD_EPSILON_CATEGORIES_ERROR',
  );

  export const LoadStabilizers = createAction('[Pacweb] LOAD_STABILIZERS');
  export const LoadStabilizersSuccess = createAction(
    '[Pacweb] LOAD_STABILIZERS_SUCCESS',
    props<{ payload: ConstructionConfig }>(),
  );
  export const LoadStabilizersError = createAction(
    '[Pacweb] LOAD_STABILIZERS_ERROR',
  );

  /**
   * Calculation
   */
  export const UpdateCalculation = createAction(
    '[Pacweb] UPDATE_CALCULATION',
    props<{ payload: Construction }>(),
  );
  export const UpdateCalculationSuccess = createAction(
    '[Pacweb] UPDATE_CALCULATION_SUCCESS',
    props<{ payload: ConstructionWithAvailableConfig }>(),
  );
  export const UpdateCalculationError = createAction(
    '[Pacweb] UPDATE_CALCULATION_ERROR',
  );
  export const SetCalculation = createAction(
    '[Pacweb] SET_CALCULATION',
    props<{ payload: ConstructionWithAvailableConfig }>(),
  );
  export const UpdateStabilizerData = createAction(
    '[Pacweb] UPDATE_STABILIZER_DATA',
    props<{ construction: Construction; stabilizerNr: number }>(),
  );
  export const UpdateStabilizerDataSuccess = createAction(
    '[Pacweb] UPDATE_STABILIZER_DATA_SUCCESS',
    props<{ payload: Construction }>(),
  );
  export const UpdateStabilizerDataError = createAction(
    '[Pacweb] UPDATE_STABILIZER_DATA_Error',
  );
  export const ValidateSSO = createAction(
    '[Pacweb] VALIDATE_SSO',
    props<{
      construction: Construction;
      option: string;
      optionValue: string;
    }>(),
  );
  export const ValidateSSOSuccess = createAction(
    '[Pacweb] VALIDATE_SSO_SUCCESS',
    props<{ payload: OptionValitaionResponse }>(),
  );
  export const ValidateSSOError = createAction('[Pacweb] VALIDATE_SSO_ERROR');

  export const OrderSSO = createAction(
    '[Pacweb] ORDER_SSO',
    props<{
      optionRequest: OptionRequest;
    }>(),
  );

  export const OrderSSOSuccess = createAction(
    '[Pacweb] ORDER_SSO_SUCCESS',
    props<{ payload: OptionOrderResponse }>(),
  );
  export const OrderSSOError = createAction('[Pacweb] ORDER_SSO_ERROR');

  export const Highlight = createAction(
    '[Pacweb] HIGHLIGHT',
    props<{ payload: string }>(),
  );

  export const SetDirty = createAction(
    '[Pacweb] SET_DIRTY',
    props<{ payload: boolean }>(),
  );

  export const SetLoad = createAction(
    '[Pacweb] SET_LOAD',
    props<{ payload: boolean }>(),
  );

  export const RequestCalculationTrigger = createAction(
    '[Pacweb] REQUEST_CALCULATION_TRIGGER',
    props<{ payload: CalculateCommand }>(),
  );

  export const RequestCalculation = createAction(
    '[Pacweb] REQUEST_CALCULATION',
    props<{ payload: Blob }>(),
  );

  export const RequestCalculationSuccess = createAction(
    '[Pacweb] REQUEST_CALCULATION_SUCCESS',
    props<{ payload: CalculationInfo }>(),
  );

  export const RequestCalculationError = createAction(
    '[Pacweb] REQUEST_CALCULATION_ERROR',
  );

  export const SaveConstruction = createAction(
    '[Pacweb] SAVE_CONSTRUCTION',
    props<{ payload: Construction }>(),
  );

  export const SaveConstructionSuccess = createAction(
    '[Pacweb] SAVE_CONSTRUCTION_SUCCESS',
    props<{ payload: Construction }>(),
  );

  export const SaveConstructionError = createAction(
    '[Pacweb] SAVE_CONSTRUCTION_ERROR',
  );

  export const LoadExportFile = createAction('[Pacweb] LOAD_EXPORT_FILE');

  export const LoadExportFileSuccess = createAction(
    '[Pacweb] LOAD_EXPORT_FILE_SUCCESS',
    props<{ payload: FileDownloadUri }>(),
  );

  export const LoadExportFileError = createAction(
    '[Pacweb] LOAD_EXPORT_FILE_ERROR',
  );

  /**
   * Archive
   */
  export const LoadArchive = createAction(
    '[Pacweb] LOAD_ARCHIVE',
    props<{ uniqueId: string; companyId: string }>(),
  );
  export const LoadArchiveSuccess = createAction(
    '[Pacweb] LOAD_ARCHIVE_SUCCESS',
    props<{ payload: CalculationInfo[] }>(),
  );
  export const LoadArchiveError = createAction('[Pacweb] LOAD_ARCHIVE_ERROR');

  export const SetPowerUser = createAction(
    '[Pacweb] SET_POWER_USER',
    props<{ payload: boolean }>(),
  );

  export const SetDeleteUser = createAction(
    '[Pacweb] SET_DELETE_USER',
    props<{ payload: boolean }>(),
  );

  export const SetTruckValid = createAction(
    '[Pacweb] SET_TRUCK_VALID',
    props<{ payload: boolean }>(),
  );

  export const SetTruckMeasurementsValid = createAction(
    '[Pacweb] SET_TRUCK_MEASUREMENTS_VALID',
    props<{ payload: boolean }>(),
  );

  export const SetFrontStabilizersValid = createAction(
    '[Pacweb] SET_FRONT_STABILIZERS_VALID',
    props<{ payload: boolean }>(),
  );

  export const SetCraneStabilizersValid = createAction(
    '[Pacweb] SET_CRANE_STABILIZERS_VALID',
    props<{ payload: boolean }>(),
  );

  export const SetRearStabilizersValid = createAction(
    '[Pacweb] SET_REAR_STABILIZERS_VALID',
    props<{ payload: boolean }>(),
  );

  export const SetCraneValid = createAction(
    '[Pacweb] SET_CRANE_VALID',
    props<{ payload: boolean }>(),
  );

  export const SetEpsilonValid = createAction(
    '[Pacweb] SET_EPSILON_VALID',
    props<{ payload: boolean }>(),
  );

  export const SetTermsAlreadyShown = createAction(
    '[Pacweb] SET_TERMS_ALREADY_SHOWN',
    props<{ payload: boolean }>(),
  );

  export const DownloadFile = createAction(
    '[Pacweb] DOWNLOAD_FILE',
    props<{ url: string; format: string; fileName?: string }>(),
  );

  export const DeleteCalculation = createAction(
    '[Pacweb] DELETE_CALCULATION',
    props<{ payload: CalculationInfo }>(),
  );

  export const DeleteCalculationSuccess = createAction(
    '[Pacweb] DELETE_CALCULATION_SUCCESS',
  );

  export const DeleteCalculationError = createAction(
    '[Pacweb] DELETE_CALCULATION_ERROR',
  );

  export const DeleteConstruction = createAction(
    '[Pacweb] DELETE_CONSTRUCTION',
  );

  export const DeleteConstructionSuccess = createAction(
    '[Pacweb] DELETE_CONSTRUCTION_SUCCESS',
  );

  export const DeleteConstructionError = createAction(
    '[Pacweb] DELETE_CONSTRUCTION_ERROR',
  );

  export const ShowStabilizers = createAction(
    '[Pacweb] SHOW_STABILIZERS',
    props<{ payload: boolean }>(),
  );
}
