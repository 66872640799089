import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DsPageModule } from '@design-system/feature/page';
import { UserService } from '@features/auth';
import { SafePipeModule } from '@shared-lib/safe-pipe';
import { map, Observable } from 'rxjs';

@Component({
  selector: 'pw-help',
  standalone: true,
  imports: [
    MatProgressSpinnerModule,
    FlexLayoutModule,
    AsyncPipe,
    SafePipeModule,
    DsPageModule,
  ],
  templateUrl: './help.component.html',
  styleUrl: './help.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HelpComponent {
  helpDocumentUrl$: Observable<string>;

  constructor(private _userService: UserService) {
    this.helpDocumentUrl$ = _userService.currentUser.pipe(
      map((user) => {
        let helpDocumentUrl =
          'https://pacweb.blob.core.windows.net/documents/PACWEB_Help_Information_new_';
        if (user) {
          switch (user.lang.toUpperCase()) {
            case 'DE':
              helpDocumentUrl += 'DE.pdf';
              break;
            case 'ES':
              helpDocumentUrl += 'ES.pdf';
              break;
            case 'FR':
              helpDocumentUrl += 'FR.pdf';
              break;
            default:
              helpDocumentUrl += 'EN.pdf';
              break;
          }
          return helpDocumentUrl;
        }
        return (helpDocumentUrl += 'EN.pdf');
      }),
    );
  }
}
