@if (helpDocumentUrl$ | async) {
  <iframe
    [src]="helpDocumentUrl$ | async | pdSafe"
    frameborder="0"
    height="1000"
    width="100%"
  ></iframe>
} @else {
  <mat-progress-spinner
    [mode]="'indeterminate'"
    color="accent"
    [diameter]="100"
  >
  </mat-progress-spinner>
}
