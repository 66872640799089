/* eslint-disable max-lines */
import { Action, createReducer, on } from '@ngrx/store';
import {
  OptionOrderResponse,
  OptionValitaionResponse,
} from '@paldesk/shared-lib/data-access/sso-generated';
import {
  CalculateCommand,
  CalculationInfo,
  Category,
  Construction,
  ConstructionConfig,
  ConstructionInfo,
  ConstructionInfoPaged,
  ConstructionWithAvailableConfig,
  Equipment,
  TruckManufacturer,
} from '../../shared/generated';
import { Constants } from '../../shared/utils/constants';
import { CranesActions } from './cranes.actions';

export interface CranesState {
  equipments: { [equipmentId: string]: Equipment } | null;
  equipmentIds: string[] | null;
  selectedEquipmentId: string | null;
  equipmentsLoading: boolean;
  equipmentsLoadingError: boolean;
  selectedCraneDataStatus: string;
  calculationInfos: { [calculationId: string]: ConstructionInfo } | null;
  calculationInfosTotalAmount: number;
  calculationInfoIds: string[] | null;
  selectedCalculationId: string | null;
  calculationInfosLoading: boolean;
  calculationInfosLoadingError: boolean;
  uploadCalculationFileLoading: boolean;
  uploadCalculationFileError: boolean;
  calculation: ConstructionWithAvailableConfig | null;
  calculationLoading: boolean;
  calculationLoadingError: boolean;
  truckManufacturers: TruckManufacturer[] | null;
  categories: Category[] | null;
  categoriesLoading: boolean;
  categoriesLoadingError: boolean;
  epsilonCategories: Category[] | null;
  epsilonCategoriesLoading: boolean;
  epsilonCategoriesLoadingError: boolean;
  helpDocumentUrl: string | null;
  stabilizers: ConstructionConfig | null;
  stabilizersLoading: boolean;
  stabilizersLoadingError: boolean;
  updateCalculationLoading: boolean;
  updateCalculationLoadingError: boolean;
  craneConfigurationErrorMessage: string | null;
  validateSSOLoading: boolean;
  validateSSOLoadingError: boolean;
  validateSSOResponse: OptionValitaionResponse | null;
  errorMessage: string | null;
  orderSSOLoading: boolean;
  orderSSOLoadingError: boolean;
  orderSSOResponse: OptionOrderResponse | null;
  highlighted: string;
  dirty: boolean;
  archive: CalculationInfo[];
  archiveLoading: boolean;
  archiveLoadingError: boolean;
  uploadPrintTrigger: boolean;
  calculationResponse: CalculationInfo | null;
  resultCalculationLoading: boolean;
  resultCalculationError: boolean;
  calculationRequest: CalculateCommand | null;
  isPowerUser: boolean;
  isDeleteUser: boolean;
  savePending: boolean;
  saveError: boolean;
  hasLoad: boolean;
  truckValid: boolean;
  truckMeasurementsValid: boolean;
  craneValid: boolean;
  epsilonValid: boolean;
  frontStabilizersValid: boolean;
  rearStabilizersValid: boolean;
  craneStabilizersValid: boolean;
  deleteCalculationLoading: boolean;
  deleteCalculationError: boolean;
  deleteConstructionLoading: boolean;
  deleteConstructionError: boolean;
  userName: string | null;
  termsAlreadyShown: boolean;
  showStabilizers: boolean;
}

const initialState: CranesState = {
  equipments: null,
  equipmentIds: null,
  selectedEquipmentId: null,
  equipmentsLoading: false,
  equipmentsLoadingError: false,
  selectedCraneDataStatus: 'SerialData',
  calculationInfos: null,
  calculationInfoIds: null,
  selectedCalculationId: null,
  calculationInfosLoading: false,
  calculationInfosLoadingError: false,
  uploadCalculationFileLoading: false,
  uploadCalculationFileError: false,
  calculation: null,
  calculationLoading: false,
  calculationLoadingError: false,
  truckManufacturers: null,
  categories: null,
  categoriesLoading: false,
  categoriesLoadingError: false,
  helpDocumentUrl: null,
  epsilonCategories: null,
  epsilonCategoriesLoading: false,
  epsilonCategoriesLoadingError: false,
  stabilizers: null,
  stabilizersLoading: false,
  stabilizersLoadingError: false,
  updateCalculationLoading: false,
  updateCalculationLoadingError: false,
  craneConfigurationErrorMessage: null,
  validateSSOLoading: false,
  validateSSOLoadingError: false,
  validateSSOResponse: null,
  errorMessage: null,
  orderSSOLoading: false,
  orderSSOLoadingError: false,
  orderSSOResponse: null,
  highlighted: '',
  dirty: true,
  archive: [],
  archiveLoading: false,
  archiveLoadingError: false,
  uploadPrintTrigger: false,
  calculationResponse: null,
  resultCalculationLoading: false,
  resultCalculationError: false,
  calculationRequest: null,
  isPowerUser: false,
  isDeleteUser: false,
  savePending: false,
  saveError: false,
  hasLoad: false,
  craneValid: true,
  truckValid: true,
  truckMeasurementsValid: true,
  epsilonValid: true,
  frontStabilizersValid: true,
  rearStabilizersValid: true,
  craneStabilizersValid: true,
  deleteCalculationLoading: false,
  deleteCalculationError: false,
  deleteConstructionLoading: false,
  deleteConstructionError: false,
  userName: null,
  termsAlreadyShown: false,
  showStabilizers: false,
  calculationInfosTotalAmount: 0,
};

export const reducer = createReducer(
  initialState,
  /**
   * Overview
   */
  on(CranesActions.LoadEquipments, (state) => ({
    ...state,
    equipmentsLoading: true,
    selectedEquipmentId: null,
    equipmentsLoadingError: false,
  })),
  on(CranesActions.LoadEquipmentsSuccess, (state, { payload }) => {
    const equipments: Equipment[] = payload;
    const newEquipments: {
      [equipmentId: string]: Equipment;
    } | null = Object.assign({}, state.equipments);
    if (newEquipments) {
      equipments.forEach((equipment) => {
        newEquipments[equipment.equipmentNumber] = equipment;
      });
    }
    return {
      ...state,
      equipments: newEquipments,
      equipmentIds: equipments.map((equipment) => equipment.equipmentNumber),
      equipmentsLoading: false,
    };
  }),
  on(CranesActions.LoadEquipmentsError, (state) => ({
    ...state,
    equipments: null,
    equipmentsLoading: false,
    equipmentsLoadingError: true,
    equipmentIds: null,
  })),
  on(CranesActions.SelectEquipment, (state, { payload }) => ({
    ...state,
    selectedEquipmentId: payload,
  })),
  on(CranesActions.SelectCraneDataStatus, (state, { payload }) => {
    const selectedCraneDataStatus: string = payload;
    return {
      ...state,
      selectedCraneDataStatus: selectedCraneDataStatus,
    };
  }),
  on(CranesActions.ResetNewCalculationScreen, (state) => ({
    ...state,
    selectedEquipmentId: null,
    equipments: null,
    equipmentsLoading: false,
    equipmentsLoadingError: false,
    equipmentIds: null,
    selectedCraneDataStatus: 'SerialData',
  })),
  on(CranesActions.SetHelpUrl, (state, { payload }) => {
    const helpUrl: string = payload;
    return {
      ...state,
      helpDocumentUrl: helpUrl,
    };
  }),
  on(CranesActions.SetUserName, (state, { payload }) => {
    const userName: string = payload;
    return {
      ...state,
      userName: userName,
    };
  }),
  /**
   * Existing calculation pop-up
   */
  on(CranesActions.LoadCalculationInfos, (state) => ({
    ...state,
    calculationInfosLoading: true,
    selectedCalculationId: null,
    calculationInfosLoadingError: false,
  })),
  on(CranesActions.LoadCalculationInfosSuccess, (state, { payload }) => {
    const calculationInfos: ConstructionInfoPaged = payload;
    const newCalculationInfos: {
      [calculationInfoId: string]: ConstructionInfo;
    } | null = Object.assign({}, state.calculationInfos);
    if (newCalculationInfos) {
      calculationInfos.constructionInfos.forEach((calculationInfo) => {
        newCalculationInfos[calculationInfo.directoryName] = calculationInfo;
      });
    }
    return {
      ...state,
      calculationInfosTotalAmount: calculationInfos.pageInfo.totalItems,
      calculationInfos: newCalculationInfos,
      calculationInfoIds: calculationInfos.constructionInfos.map(
        (calculationInfo) => calculationInfo.directoryName,
      ),
      calculationInfosLoading: false,
    };
  }),
  on(CranesActions.LoadCalculationInfosError, (state) => ({
    ...state,
    calculationInfos: null,
    calculationInfosLoading: false,
    calculationInfosLoadingError: true,
    calculationInfoIds: null,
  })),
  on(CranesActions.SelectCalculationId, (state, { payload }) => ({
    ...state,
    selectedCalculationId: payload,
  })),
  on(CranesActions.ResetExistingCalculationScreen, (state) => ({
    ...state,
    selectedCalculationId: null,
  })),
  /**
   * Import file pop-up
   */
  on(CranesActions.UploadCalculationFile, (state) => ({
    ...state,
    selectedCalculationId: null,
    uploadCalculationFileError: false,
    uploadCalculationFileLoading: true,
  })),
  on(CranesActions.UploadCalculationFileSuccess, (state, { payload }) => {
    const calculationInfo: ConstructionInfo = payload;
    return {
      ...state,
      selectedCalculationId: calculationInfo.uniqueId || '',
      uploadCalculationFileLoading: false,
    };
  }),
  on(CranesActions.UploadCalculationFileError, (state) => ({
    ...state,
    selectedCalculationId: null,
    uploadCalculationFileError: true,
    uploadCalculationFileLoading: false,
  })),
  /**
   * Calculation screen
   */
  on(CranesActions.LoadNewCalculation, (state) => ({
    ...state,
    calculationLoading: true,
    calculationLoadingError: false,
  })),
  // eslint-disable-next-line sonarjs/no-identical-functions
  on(CranesActions.LoadCalculation, (state) => ({
    ...state,
    calculationLoading: true,
    calculationLoadingError: false,
  })),
  // eslint-disable-next-line sonarjs/no-identical-functions
  on(CranesActions.LoadCalculationByDebitorId, (state) => ({
    ...state,
    calculationLoading: true,
    calculationLoadingError: false,
  })),
  on(CranesActions.LoadNewCalculationSuccess, (state, { payload }) => {
    const newCalculation: ConstructionWithAvailableConfig =
      getCalculationUpdatedWithDefaults(payload);
    return {
      ...state,
      calculation: newCalculation,
      calculationLoading: false,
      dirty: true,
      hasLoad: newCalculation.construction
        ? hasLoad(newCalculation.construction)
        : false,
    };
  }),
  on(CranesActions.LoadExistingCalculationSuccess, (state, { payload }) => {
    const calculation: ConstructionWithAvailableConfig = payload;
    return {
      ...state,
      calculation: calculation,
      calculationLoading: false,
      dirty: true,
      hasLoad: calculation.construction
        ? hasLoad(calculation.construction)
        : false,
    };
  }),
  on(CranesActions.LoadCalculationError, (state) => ({
    ...state,
    calculation: null,
    calculationLoading: false,
    calculationLoadingError: true,
  })),
  on(CranesActions.ResetCalculation, (state) => ({
    ...state,
    calculation: null,
    calculationLoading: false,
    calculationLoadingError: false,
    selectedCalculationId: null,
    dirty: true,
    highlighted: '',
  })),
  on(CranesActions.RequestCalculationTrigger, (state, { payload }) => {
    const calculationRequest: CalculateCommand = payload;
    return {
      ...state,
      uploadPrintTrigger: true,
      resultCalculationError: false,
      resultCalculationLoading: true,
      calculationRequest: calculationRequest,
    };
  }),
  on(CranesActions.RequestCalculation, (state) => ({
    ...state,
    uploadPrintTrigger: false,
  })),
  on(CranesActions.RequestCalculationSuccess, (state, { payload }) => {
    const calculationResponse: CalculationInfo = payload;
    return {
      ...state,
      calculationResponse: calculationResponse,
      resultCalculationError: false,
      resultCalculationLoading: false,
    };
  }),
  on(CranesActions.RequestCalculationError, (state) => ({
    ...state,
    calculationResponse: null,
    resultCalculationError: true,
    resultCalculationLoading: false,
  })),
  /**
   * Truck
   */
  on(CranesActions.LoadTruckManufacturers, (state) => ({
    ...state,
    truckManufacturers: null,
  })),
  on(CranesActions.LoadTruckManufacturersSuccess, (state, { payload }) => {
    const truckManufacturers: TruckManufacturer[] = payload;
    return {
      ...state,
      truckManufacturers: truckManufacturers,
    };
  }),
  /**
   * Crane
   */
  on(CranesActions.LoadCategoriesSuccess, (state, { payload }) => {
    const categories: Category[] = payload;
    return {
      ...state,
      categories: categories,
      categoriesLoading: false,
    };
  }),
  on(CranesActions.LoadCategoriesError, (state) => ({
    ...state,
    categories: null,
    categoriesLoading: false,
    categoriesLoadingError: true,
  })),
  on(CranesActions.LoadEpsilonCategories, (state) => ({
    ...state,
    epsilonCategories: null,
    epsilonCategoriesLoading: true,
    epsilonCategoriesLoadingError: false,
  })),
  on(CranesActions.LoadEpsilonCategoriesSuccess, (state, { payload }) => {
    const epsilonCategories: Category[] = payload;
    return {
      ...state,
      epsilonCategories: epsilonCategories,
      epsilonCategoriesLoading: false,
    };
  }),
  on(CranesActions.LoadEpsilonCategoriesError, (state) => ({
    ...state,
    epsilonCategories: null,
    epsilonCategoriesLoading: false,
    epsilonCategoriesLoadingError: true,
  })),
  on(CranesActions.LoadCategories, (state) => ({
    ...state,
    categories: null,
    categoriesLoading: true,
    categoriesLoadingError: false,
  })),
  on(CranesActions.LoadStabilizers, (state) => ({
    ...state,
    stabilizers: null,
    stabilizersLoading: true,
    stabilizersLoadingError: false,
  })),
  on(CranesActions.LoadStabilizersSuccess, (state, { payload }) => {
    const stabilizers: ConstructionConfig = payload;
    return {
      ...state,
      stabilizers: stabilizers,
      stabilizersLoading: false,
    };
  }),
  on(CranesActions.LoadStabilizersError, (state) => ({
    ...state,
    stabilizers: null,
    stabilizersLoading: false,
    stabilizersLoadingError: true,
  })),
  /**
   * Calculation
   */
  on(CranesActions.UpdateCalculationSuccess, (state, { payload }) => {
    const updatedCalculation: ConstructionWithAvailableConfig = payload;
    return {
      ...state,
      calculation: { ...updatedCalculation },
      updateCalculationLoading: false,
      updateCalculationLoadingError: false,
      hasLoad: updatedCalculation.construction
        ? hasLoad(updatedCalculation.construction)
        : false,
      dirty: true,
    };
  }),
  on(CranesActions.UpdateCalculationError, (state) => ({
    ...state,
    updateCalculationLoading: false,
    updateCalculationLoadingError: true,
    errorMessage: 'Invalid crane data',
  })),
  on(CranesActions.SetCalculation, (state, { payload }) => {
    const setCalculation: ConstructionWithAvailableConfig = payload;
    return {
      ...state,
      calculation: { ...setCalculation },
      validateSSOLoading: false,
      validateSSOLoadingError: false,
      updateCalculationLoading: false,
      updateCalculationLoadingError: false,
      dirty: true,
    };
  }),
  on(CranesActions.UpdateCalculation, (state) => ({
    ...state,
    updateCalculationLoading: true,
    updateCalculationLoadingError: false,
  })),
  // eslint-disable-next-line sonarjs/no-identical-functions
  on(CranesActions.UpdateStabilizerData, (state) => ({
    ...state,
    updateCalculationLoading: true,
    updateCalculationLoadingError: false,
  })),
  on(CranesActions.UpdateStabilizerDataSuccess, (state, { payload }) => {
    const updatedConstruction: Construction = payload;
    return {
      ...state,
      calculation: {
        ...state.calculation,
        construction: updatedConstruction,
      } as ConstructionWithAvailableConfig,
      updateCalculationLoading: false,
      updateCalculationLoadingError: false,
      hasLoad: hasLoad(updatedConstruction),
      dirty: true,
    };
  }),
  on(CranesActions.UpdateStabilizerDataError, (state) => ({
    ...state,
    updateCalculationLoading: false,
    updateCalculationLoadingError: true,
    errorMessage: 'Invalid stabilizer data',
  })),
  on(CranesActions.ValidateSSO, (state) => ({
    ...state,
    validateSSOLoading: true,
    validateSSOLoadingError: false,
    validateSSOResponse: null,
    orderSSOLoading: false,
    orderSSOLoadingError: false,
    orderSSOResponse: null,
  })),
  on(CranesActions.ValidateSSOError, (state) => ({
    ...state,
    validateSSOLoading: false,
    validateSSOLoadingError: true,
    validateSSOResponse: null,
    errorMessage:
      'Option "Semitrailer" is not available for this crane. Please contact PALFINGER Support!',
  })),
  on(CranesActions.ValidateSSOSuccess, (state, { payload }) => {
    const ssoResponse: OptionValitaionResponse = payload;
    return {
      ...state,
      validateSSOLoading: false,
      validateSSOLoadingError: false,
      validateSSOResponse: ssoResponse,
    };
  }),
  on(CranesActions.OrderSSO, (state) => ({
    ...state,
    orderSSOLoading: true,
    orderSSOLoadingError: false,
    orderSSOResponse: null,
  })),
  on(CranesActions.OrderSSOError, (state) => ({
    ...state,
    orderSSOLoading: false,
    orderSSOLoadingError: true,
    orderSSOResponse: null,
  })),
  on(CranesActions.OrderSSOSuccess, (state, { payload }) => {
    const orderResponse: OptionOrderResponse = payload;
    return {
      ...state,
      orderSSOLoading: false,
      orderSSOLoadingError: false,
      orderSSOResponse: orderResponse,
    };
  }),
  on(CranesActions.Highlight, (state, { payload }) => ({
    ...state,
    highlighted: payload,
  })),
  on(CranesActions.SetDirty, (state, { payload }) => ({
    ...state,
    dirty: payload,
  })),
  on(CranesActions.LoadArchive, (state) => ({
    ...state,
    archive: [],
    archiveLoading: true,
    archiveLoadingError: false,
  })),
  on(CranesActions.LoadArchiveSuccess, (state, { payload }) => ({
    ...state,
    archive: payload,
    archiveLoading: false,
    archiveLoadingError: false,
  })),
  on(CranesActions.LoadArchiveError, (state) => ({
    ...state,
    archive: [],
    archiveLoading: false,
    archiveLoadingError: true,
  })),
  on(CranesActions.SetPowerUser, (state, { payload }) => {
    const isPowerUser = payload;
    return {
      ...state,
      isPowerUser: isPowerUser,
    };
  }),
  on(CranesActions.SetDeleteUser, (state, { payload }) => {
    const isDeleteUser = payload;
    return {
      ...state,
      isDeleteUser: isDeleteUser,
    };
  }),
  on(CranesActions.SaveConstruction, (state) => ({
    ...state,
    ...state,
    savePending: true,
    saveError: false,
    calculationResponse: null,
    resultCalculationError: false,
    resultCalculationLoading: false,
  })),
  on(CranesActions.SaveConstructionSuccess, (state, { payload }) => {
    const construction = payload;
    return {
      ...state,
      calculation: {
        ...state.calculation,
        construction: construction,
      } as ConstructionWithAvailableConfig,
      hasLoad: hasLoad(construction),
      savePending: false,
      saveError: false,
      dirty: false,
    };
  }),
  on(CranesActions.SaveConstructionError, (state) => ({
    ...state,
    savePending: false,
    saveError: true,
  })),
  on(CranesActions.SetTruckValid, (state, { payload }) => {
    const validTruck: boolean = payload;
    return {
      ...state,
      truckValid: validTruck,
    };
  }),

  on(CranesActions.SetTruckMeasurementsValid, (state, { payload }) => {
    const validTruckMeasurements: boolean = payload;
    return {
      ...state,
      truckMeasurementsValid: validTruckMeasurements,
    };
  }),

  on(CranesActions.SetFrontStabilizersValid, (state, { payload }) => {
    const frontStabilizersValid: boolean = payload;
    return {
      ...state,
      frontStabilizersValid: frontStabilizersValid,
    };
  }),
  on(CranesActions.SetRearStabilizersValid, (state, { payload }) => {
    const rearStabilizersValid: boolean = payload;
    return {
      ...state,
      rearStabilizersValid: rearStabilizersValid,
    };
  }),
  on(CranesActions.SetCraneStabilizersValid, (state, { payload }) => {
    const craneStabilizersValid: boolean = payload;
    return {
      ...state,
      craneStabilizersValid: craneStabilizersValid,
    };
  }),
  on(CranesActions.SetCraneValid, (state, { payload }) => {
    const validCrane: boolean = payload;
    return {
      ...state,
      craneValid: validCrane,
    };
  }),
  on(CranesActions.SetEpsilonValid, (state, { payload }) => {
    const validEpsilon: boolean = payload;
    return {
      ...state,
      epsilonValid: validEpsilon,
    };
  }),
  on(CranesActions.SetTermsAlreadyShown, (state, { payload }) => {
    const alreadyShown: boolean = payload;
    return {
      ...state,
      termsAlreadyShown: alreadyShown,
    };
  }),

  on(CranesActions.DeleteCalculation, (state) => ({
    ...state,
    deleteCalculationLoading: true,
    deleteCalculationError: false,
  })),
  on(CranesActions.DeleteCalculationSuccess, (state) => ({
    ...state,
    deleteCalculationLoading: false,
    deleteCalculationError: false,
  })),
  on(CranesActions.DeleteCalculationError, (state) => ({
    ...state,
    deleteCalculationLoading: false,
    deleteCalculationError: true,
  })),
  on(CranesActions.DeleteConstruction, (state) => ({
    ...state,
    deleteConstructionLoading: true,
    deleteConstructionError: false,
  })),
  on(CranesActions.DeleteConstructionSuccess, (state) => ({
    ...state,
    deleteConstructionLoading: false,
    deleteConstructionError: false,
  })),
  on(CranesActions.DeleteConstructionError, (state) => ({
    ...state,
    deleteConstructionLoading: false,
    deleteConstructionError: true,
  })),
  on(CranesActions.ShowStabilizers, (state, { payload }) => {
    const showStabilizers: boolean = payload;
    return {
      ...state,
      showStabilizers: showStabilizers,
    };
  }),
);

export function cranesReducer(state: CranesState | undefined, action: Action) {
  return reducer(state, action);
}

function getCalculationUpdatedWithDefaults(
  calculation: ConstructionWithAvailableConfig,
) {
  const cloneCalc: ConstructionWithAvailableConfig = JSON.parse(
    JSON.stringify(calculation),
  );
  if (cloneCalc.construction.eqart === Constants.RAILWAY) {
    cloneCalc.construction.truck.centerOfGravityZ = 0;
    cloneCalc.construction.truck.lowerEdgeZ = 0;
    cloneCalc.construction.truck.trackInclination =
      Constants.TILT_DEGREE_DEFAULT;
  }
  if (cloneCalc.construction.crane.craneStabilizer) {
    cloneCalc.construction.crane.distanceToV1 =
      Constants.CalculationDefaults.craneStabDistance;
    cloneCalc.construction.crane.craneStabilizer.distance =
      Constants.CalculationDefaults.craneStabDistance;
  }
  cloneCalc.construction.truck.vehicleType = Constants.Carrier;
  cloneCalc.construction.truck.front.tipPointDistance =
    Constants.CalculationDefaults.distanceFront;
  cloneCalc.construction.truck.rear.tipPointDistance =
    Constants.CalculationDefaults.distanceRear;
  if (cloneCalc.construction.truck.rear.h1)
    cloneCalc.construction.truck.rear.h1.distance =
      Constants.CalculationDefaults.rearAxleDistance;
  cloneCalc.construction.truck.front.leftWeight =
    Constants.CalculationDefaults.frontWeight;
  cloneCalc.construction.truck.rear.leftWeight =
    Constants.CalculationDefaults.rearWeight;
  if (hasLoad(cloneCalc.construction)) {
    cloneCalc.construction.truck.platformX =
      Constants.CalculationDefaults.loadX;
    cloneCalc.construction.truck.platformY =
      Constants.CalculationDefaults.loadY;
    cloneCalc.construction.truck.platformWidth =
      Constants.CalculationDefaults.loadWidth;
    cloneCalc.construction.truck.platformHeight =
      Constants.CalculationDefaults.loadHeight;
    cloneCalc.construction.truck.platformWeight =
      Constants.CalculationDefaults.loadWeight;
  }
  cloneCalc.construction.crane.vehicleQuadrantASide = -1;
  return cloneCalc;
}

function hasLoad(construction: Construction) {
  if (
    construction.eqart === Constants.SAP ||
    construction.eqart === Constants.RAILWAY
  ) {
    return construction.crane.sapCraneConfig?.hpscp
      ? construction.crane.sapCraneConfig.hpscp.toLowerCase().includes('load')
      : false;
  } else if (construction.eqart === Constants.EPSILON) {
    return construction.crane.sapEpsilonConfig?.y_GR110
      ? construction.crane.sapEpsilonConfig.y_GR110.toLowerCase() === 'load'
      : false;
  }
  return false;
  // tslint:disable-next-line: max-file-line-count
}
