import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { TranslateModule } from '@ngx-translate/core';
import {
  DeprecatedTableFilterAdvancedBodyDirective,
  DeprecatedTableFilterAdvancedHeaderDirective,
  DeprecatedTableFilterComponent,
} from './table-filter.component';

// TableFilter module is deprecated in the new design system
// Use DsFilter instead
@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    FlexLayoutModule,
    MatExpansionModule,
    MatTooltipModule,
    TranslateModule,
    DsSpacingModule,
  ],
  declarations: [
    DeprecatedTableFilterComponent,
    DeprecatedTableFilterAdvancedHeaderDirective,
    DeprecatedTableFilterAdvancedBodyDirective,
  ],
  exports: [
    DeprecatedTableFilterComponent,
    DeprecatedTableFilterAdvancedHeaderDirective,
    DeprecatedTableFilterAdvancedBodyDirective,
  ],
})
export class DeprecatedTableFilterModule {}
